import React, { useContext, useEffect, useMemo, useState } from "react";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import { Row, Col, Button, Container } from "reactstrap";
import { useErrors } from "services/customHooks/useErrors";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import TextFormInput from "components/framework/forms/TextFormInput";
import AccountDetails from "../../AccountDetails";
import NpacMaintenanceAlert from "../../NpacMaintenanceAlert";
import { DocumentModel } from "components/orders/types/DocumentModel";
import { nameOf } from "services/util/ObjectUtil";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { getUtcDate, isAfterNextYearToday, isSunday } from "services/util/DateUtil";
import { PortInAccountModel } from "components/orders/types/PortInAccountModel";
import ServiceAddress from "components/orders/ServiceAddress";
import Documents from "components/orders/Documents";
import CsrRequest from "components/orders/CsrRequest";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { CustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";
import { ServiceAddressDto } from "services/apis/types/port/ServiceAddressDto";
import { isNumber, isValidZipCode } from "services/util/StringUtil";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import AutoActivationModal from "./AutoActivationModal";
import moment, { Moment } from "moment";
import { AppContext } from 'services/appContext/AppContext';
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { FullOrPartialPort } from "./FullOrPartialPort";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  orderRequestType: OrderRequestType;
  documents: Array<DocumentModel>;
  showCustomerDetails: boolean;
  showLoadingIndicator: boolean;
  overviewModel: PortInAccountModel;
  setPortInOverviewModel: (overviewModel: PortInAccountModel) => void;
  addDocument: (document: DocumentModel) => void;
  showApplyAll: boolean;
  applyAll: (portInAccount: PortInAccountModel) => void;
  deleteDocument: (document: DocumentModel) => void;
  disabled: boolean;
  portProposals: PortProposalDto | undefined;
};

export default function PortInOverview(props: Props) {
  
  const { setErrors, getErrorHandler } = useErrors();

  const { overviewModel, setPortInOverviewModel }: Props = props;
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const [convertedFromPreviousOrder] = useState(()=> props.overviewModel.previousOrder !== undefined);
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [isAutoActivationSelected, setIsAutoActivationSelected] = useState(false);
  const [selectedActivationTime, setSelectedActivationTime] = useState("");
  const [selectedActivationTimeZone, setSelectedActivationTimeZone] = useState("");
  const accountDetailsRequiredFields = useMemo(()=> {
    if (props.overviewModel.csrRequested) {
      return  ["authorizedName", "billingPhoneNumber"];
    }
    
    return undefined; // will use default required fields
  },[props.overviewModel.csrRequested]);

  const canDisplayCustomerDetailsField = (fieldName: string) => {
    const found = (collection: any[], value: string)=> collection.some(v=> v && v.toLocaleLowerCase() === value?.toLocaleLowerCase());
    
    const defaultFieldsHasBeenOverriden = overrideCustomerDetailsFieldsOrUndefined && overrideCustomerDetailsFieldsOrUndefined.length > 0;
    
    return defaultFieldsHasBeenOverriden 
      ? found(overrideCustomerDetailsFieldsOrUndefined as string[], fieldName)
      : found(DefaultCustomerDetailsFields, fieldName);
  };

  const handleSubmit = (selectedTime: string, selectedTimeZone: string) => {
    setSelectedActivationTime(selectedTime);
    setSelectedActivationTimeZone(selectedTimeZone);
    const formattedTime = moment(selectedTime, "hh:mm A").format("HH:mm:ss");

    let selectedDueDate: moment.Moment | string = props.overviewModel.dueDate
        ? moment.utc(props.overviewModel.dueDate)
        : "";
        if (moment.isMoment(selectedDueDate) && selectedDueDate.isValid()) {
          const formattedDate = (selectedDueDate as moment.Moment).format('YYYY-MM-DD');
          setSelectedActivationTime(formattedDate + " " + selectedTime);
        const combinedDateTime = `${formattedDate}T${formattedTime}`;
        props.setPortInOverviewModel({ ...props.overviewModel, autoActivationDate: combinedDateTime, timeZone: selectedTimeZone});
    }
  };

  const handleDueDateChange = (value) => {
    if(props.overviewModel.dueDate) {
      const formattedTime = moment(selectedActivationTime, "hh:mm A").format("HH:mm:ss");
      let selectedDueDate: moment.Moment | string = value ? moment.utc(value) : "";
      const formattedDate = (selectedDueDate as moment.Moment).format('YYYY-MM-DD');
      props.setPortInOverviewModel({
        ...props.overviewModel,
        autoActivationDate: formattedTime ? `${formattedDate}T${formattedTime}` : "",
        dueDate: value
      });
    }
  };


  useEffect(() => {
    let errors = validatePortInOverview(overviewModel, canDisplayCustomerDetailsField("serviceAddress"));
    const hasErrors = HasErrors(errors);
    setErrors(errors);
    if (hasErrors !== overviewModel.hasErrors) {
      setPortInOverviewModel({ ...overviewModel, hasErrors });
    }
    // eslint-disable-next-line
  }, [overviewModel, setPortInOverviewModel, setErrors]);

  const overrideCustomerDetailsFieldsOrUndefined = useMemo(()=>{
    if (props.orderRequestType === OrderRequestType.CSROnly){
        return [...DefaultCustomerDetailsFields].filter((field) => !["csrRequest"].some(e=> e === field));
    }

    if (convertedFromPreviousOrder && props.orderRequestType === OrderRequestType.PreOrderAndOrder) {
      return [...DefaultCustomerDetailsFields].filter((field) => !["csrRequest"].some(e=> e === field));
    }

    return undefined;
  },[props.orderRequestType, convertedFromPreviousOrder]);


  return (
    <>
      {isSunday(props.overviewModel.dueDate) && (
        <NpacMaintenanceAlert classNames="pc-npac-maintenance-warning" />
      )}
      <Row>
        <Col md={12}>
          <CardSectionHeader labelTranslationId="orders.portIn.stepTwo.overview" />
          <Row className="mb-2">
            <Col md={3}>
              <DateTimeFormInput
                required
                disabled={props.disabled}
                labelTranslationId={!isMixNetworkUrl ? "orders.portIn.stepTwo.dueDate" : "orders.portIn.stepTwo.activationDate"}
                value={props.overviewModel.dueDate}
                handleInputChange={(value) =>{
                  props.setPortInOverviewModel({ ...props.overviewModel, dueDate: value });
                  if(props.overviewModel.autoActivationDate) {
                    handleDueDateChange(value)
                  }
                }
                }
                errorHandler={getErrorHandler(nameOf<PortInAccountModel>("dueDate"))}
              />
            </Col>
            {!isMixNetworkUrl && (
              <Col md={3}>
                <DateTimeFormInput
                  disabled={props.disabled}
                  labelTranslationId="orders.portIn.stepTwo.dueTime"
                  value={props.overviewModel.dueDate ? props.overviewModel.dueDate : getUtcDate()}
                  handleInputChange={(value) =>
                    props.setPortInOverviewModel({ ...props.overviewModel, dueDate: value })
                  }
                  errorHandler={getErrorHandler(nameOf<PortInAccountModel>("dueDate"))}
                  isTimeInput
                />
              </Col>
            )}
            <Col md={3}>
              <TextFormInput
                readonly={props.disabled}
                labelTranslationId="orders.portIn.stepTwo.projectId"
                value={props.overviewModel.projectId}
                handleInputChange={(value) =>
                  props.setPortInOverviewModel({ ...props.overviewModel, projectId: value })
                }
                errorHandler={getErrorHandler(nameOf<PortInAccountModel>("projectId"))}
              />
            </Col>
            {(props.orderRequestType === OrderRequestType.CSROnly || props.orderRequestType === OrderRequestType.PreOrderAndOrder) && (
            <Col md={3}>
             <DropdownFormInput  
                required          
                labelTranslationId="orders.portIn.stepTwo.FullOrPartialPort"
                value={props.overviewModel.fullOrPartialPort}
                handleInputChange={(value) =>
                props.setPortInOverviewModel({
                  ...props.overviewModel,
                  fullOrPartialPort: value
                })
              }                          
                errorHandler={getErrorHandler(nameOf<PortInAccountModel>("fullOrPartialPort"))}
                options={[
                  { key: "Select", value: ""},
                  { key: "Full", value: FullOrPartialPort.Full },
                  { key: "Partial", value: FullOrPartialPort.Partial }
                ]}
              /> 
           </Col>
            )}
            {!(appContext.localStorageInfo.selectedProfile?.external || props.orderRequestType === OrderRequestType.CSROnly) && (
              <Col md={3}>
                <div className="btn-checkbox">
                  <CheckboxFormInput
                    disabled={!props.overviewModel.dueDate}
                    tooltipId={
                      isMixNetworkUrl ?
                      props.overviewModel.dueDate ? undefined : "orders.autoActivation.activationDate.selected" :
                      props.overviewModel.dueDate ? undefined : "orders.autoActivation.dueDate.selected"
                    }
                    label={
                      isMixNetworkUrl ?
                      intl.formatMessage({ id: "orders.portIn.stepTwo.autoActivationTime" }) :
                      intl.formatMessage({ id: "orders.portIn.stepTwo.autoActivation" })
                    }
                    value={props.overviewModel.autoActivationDate}
                    checked={props.overviewModel.autoActivationDate ? true : false}
                    handleInputChange={(value) => {
                      if(selectedActivationTime) {
                        setIsAutoActivationSelected(isAutoActivationSelected);
                        setShowModal(showModal);
                        setSelectedActivationTime("")
                        props.setPortInOverviewModel({ ...props.overviewModel, autoActivationDate: "", timeZone: ""});
                      } else {
                        setIsAutoActivationSelected(!isAutoActivationSelected);
                        setShowModal(!showModal);
                      }
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>

          <Container fluid>
            {isAutoActivationSelected && showModal && (
              <AutoActivationModal
                selectedActivationTime={selectedActivationTime}
                selectedActivationTimeZone={selectedActivationTimeZone}
                closeModal={() => {
                  setShowModal(false)
                  setIsAutoActivationSelected(false);
                }}
                onSubmit={handleSubmit}
              />
            )}
          </Container>

          {(props.showApplyAll && (
            <Row className="mb-2">
                <Col>
                  <Button
                    color="primary"
                    className={`btn-sm ${theme === "light" ? "bg-lblue no-border" : ""}`}
                    onClick={()=>{
                      props.applyAll(props.overviewModel)
                    }}
                    disabled={ props.showLoadingIndicator }>
                    {props.showLoadingIndicator && (
                      <i className="fas fa-spinner fa-spin mr-2" />
                    )}
                    <FormattedMessage id="orders.portIn.stepTwo.applyAll" />
                  </Button>
                </Col>
            </Row>
          ))}
          
          {(props.showCustomerDetails || isMixNetworkUrl) && (
            <>
              {canDisplayCustomerDetailsField("documents") && (
                <Col md={6} className="px-0 mt-3">
                  <Documents
                    documents={props.documents}
                    addDocument={props.addDocument}
                    deleteDocument={props.deleteDocument}
                    disabled={props.disabled}
                    fileInputMarginTop={false}
                  />
                </Col>
              )}
              
              {canDisplayCustomerDetailsField("csrRequest") && (
                <CsrRequest
                csrRequest={props.overviewModel.csrRequested}
                setCsrRequest={(value)=>
                  props.setPortInOverviewModel({ ...props.overviewModel, csrRequested : value })
                }
                disabled={props.disabled}
              />
              )}

              {canDisplayCustomerDetailsField("accountDetails") && (
                <AccountDetails
                customerDetails={props.overviewModel.customerDetails}
                overrideRequiredFields ={accountDetailsRequiredFields}
                setCustomerDetails={(value) =>
                  props.setPortInOverviewModel({ ...props.overviewModel, customerDetails: value })
                }
                getErrorHandler={getErrorHandler}
                disabled={props.disabled}
              />
              )}
              
              {canDisplayCustomerDetailsField("serviceAddress") && (
                <ServiceAddress
                serviceAddress={props.overviewModel.customerDetails.serviceAddress}
                setServiceAddress={(value) =>
                  props.setPortInOverviewModel({
                    ...props.overviewModel,
                    customerDetails: {
                      ...props.overviewModel.customerDetails,
                      serviceAddress: value
                    }
                  })
                }
                getErrorHandler={getErrorHandler}
                disabled={props.disabled}
              />
              )}

            </>
          )}
        </Col>
      </Row>
    </>
  );
}

const validatePortInOverview = (overview: PortInAccountModel, isServiceAddressApplicable: boolean) => {
  const errors: Errors = {};
  if (overview.dueDate && isAfterNextYearToday(overview.dueDate)) {
    AddError(
      errors,
      nameOf<PortInAccountModel>("dueDate"),
      "orders.portIn.stepTwo.dueDate.dateTooFarError"
    );
  }

  if (isServiceAddressApplicable && overview.customerDetails.serviceAddress.zipCode && !isValidZipCode(overview.customerDetails.serviceAddress.zipCode)) {
    AddError(
      errors,
      nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
      "customerDetails",
      "serviceAddress",
      "zipCode"
      ),
      "orders.portIn.stepTwo.dueDate.zipCodeError"
    )
  }

  if (overview.customerDetails.billingPhoneNumber && (!isNumber(overview.customerDetails.billingPhoneNumber) || overview.customerDetails.billingPhoneNumber.length < 10 || overview.customerDetails.billingPhoneNumber.length > 15)) {
    AddError(
      errors,
      nameOf<PortInAccountModel, CustomerDetailsDto>(
      "customerDetails",
      "billingPhoneNumber",
      ),
      "orders.portIn.stepTwo.billingPhoneNumber"
    )
  }

  return errors;
};

export const DefaultCustomerDetailsFields : string[] = ["documents", "csrRequest", "accountDetails", "serviceAddress"];
