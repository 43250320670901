export const EnvironmentUtil = {
  apiV1Url: (path: string): string => `${process.env.REACT_APP_API_URL}/api/${path}`,
  apiV2Url: (path: string): string => `${process.env.REACT_APP_API_URL}/v2/${path}`,
  apiV3Url: (path: string): string => `${process.env.REACT_APP_API_URL}/v3/${path}`,
  identityServerUrl: (path: string): string =>
    `${process.env.REACT_APP_IDENTITY_SERVER_URL}/${path}`,
  frontendUrl: (path: string): string => `${process.env.REACT_APP_FRONTEND_URL}/#/${path}`,
  isMixNetwork: (window.location.href).includes(process.env.REACT_APP_MIX_FRONTEND_URL || ""),
  isCspire: (window.location.href).includes(process.env.REACT_APP_Cspire_FRONTEND_URL || ""),
  signalRUrl: `${process.env.REACT_APP_API_URL}/hub/notification`,
  oAuthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  oAuthClientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  oAuthScope: process.env.REACT_APP_OAUTH_SCOPE,
  version: process.env.REACT_APP_BUILD
};
