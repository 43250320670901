import { OrderHandler } from "../port/OrderHandler";

export type OrderMetadataDto = {
  createdBy: string;
  createdTimestamp: Date;
  modifiedBy: string;
  correspondingOrderId: string;
  modifiedTimestamp: Date;
  projectId: string;
  handledBy: keyof typeof OrderHandler;
  adminProjectId: string;
  orderType?: string;
  fullOrPartialPort:string
};

export const DefaultOrderMetadataDto = {
  createdBy: "",
  createdTimestamp: new Date(),
  modifiedBy: "",
  correspondingOrderId: "",
  modifiedTimestamp: new Date(),
  projectId: "",
  handledBy: OrderHandler.ServiceProvider,
  fullOrPartialPort:"",
  adminProjectId: "",
  orderType: ""
};
