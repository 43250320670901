import React, { useCallback, useContext } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { HubSpotCallToAction } from "components/common/HubSpotCallToAction";
import { AppContext } from "services/appContext/AppContext";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork
const isCspireUrl = EnvironmentUtil.isCspire

export default function Footer() {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const isLoggedIn = appContext.localStorageInfo.user;

  const isFreeUser = useCallback(()=> appContext.localStorageInfo.selectedProfile?.external,[ appContext.localStorageInfo.selectedProfile]);

  const hubspotParameter = useCallback((parameterName: string)=> {
    const userType = isFreeUser() ? "freeUser" : "paidUser";
    return intl.formatMessage({id: `adminNavBar.hubSpot.callToAction.${userType}.${parameterName}`});
  },[intl, isFreeUser]);

  return (
    <footer className="mt-3" id="footer-main">
      <Container fluid>
        <Row className="align-items-center">
        {isCspireUrl ? (
          <Col xs="12" className="d-flex justify-content-between align-items-center flex-wrap">         
          <div className="d-flex align-items-center flex-wrap">
            <div className="footer-font-size d-flex align-items-center">
              © {new Date().getFullYear()}&nbsp;
              <FormattedMessage id="footer.copyright.cspire" />
            </div>
            <div className="d-flex align-items-center ml-3">
              <Nav className="nav-footer d-flex align-items-center">
                <NavItem>
                  <NavLink href="https://www.cspire.com/web/legal/privacy-policy.html" target="_blank">
                    <FormattedMessage id="footer.privacyPolicy" />
                  </NavLink>
                </NavItem>
                <span className="mx-2">|</span>
                <NavItem>
                  <NavLink href="https://www.cspire.com/web/wireless/policies-agreements.html" target="_blank">
                    <FormattedMessage id="footer.otherPoliciesAndAgreements" />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>   
          <div className="d-flex align-items-center">
          <NavLink href="https://www.bbb.org/mississippi/business-reviews/cellular-telephone-service-and-supplies/c-spire-wireless-in-ridgeland-ms-3000083/?__cf_chl_tk=uwCVIrvjfkw1B_NDX9CcZrLG8pEjOIF4Bw2m.iQSMng-1734800027-1.0.1.1-L1ab7nI5qNB8zqurS01LQ6JnsUxGCFLSJ7p3XvrL4W0#bbbonlineclick" target="_blank">
              <img src={require("assets/img/cspire_footer1.png")} alt="BBB Accredited Business" className="ml-3" width="100" />
            </NavLink>
            <NavLink href="https://www.cspire.com/web/business/solutions/carrier-wholesale" target="_blank">
              <img src={require("assets/img/cspire_footer2.png")} alt="Carrier Wholesale" className="ml-3" width="100" />
            </NavLink>
          </div>
      </Col>    
                  
          ) : (
            <>
          <Col xs="12" md={isMixNetworkUrl ? "6" : "4"}>
            <div className={`copyright d-flex justify-content-center justify-content-md-start ${theme === "light" ? "text-crow" : "text-muted"}`}>
              <div className="footer-font-size">{isMixNetworkUrl ? "Copyright" : "©"} {new Date().getFullYear()}</div>
              <div className={`ml-3 footer-font-size ${theme === "light" ? "text-crow" : ""}`}>
                <FormattedMessage id={isMixNetworkUrl ? "footer.copyright.mixNetwork" : "footer.copyright"}/>
              </div>
              {!isMixNetworkUrl && (
                <Link
                  className={`font-weight-bold footer-font-size ml-3 ${theme === "light" ? "lblue-color" : ""}`}
                  to="/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.portControl" /> {process.env.REACT_APP_VERSION}
              </Link>
              )}
            </div>
          </Col>
          {!isMixNetworkUrl && !isLoggedIn && <Col md="4"></Col>}
          {!isMixNetworkUrl && isLoggedIn &&
            <Col xs="12" md="4">            
              <div className="nav-footer justify-content-center">
                <div className="hubspot-banner">
                  <HubSpotCallToAction
                    portalId={hubspotParameter("portalId")}
                    ctaToken={hubspotParameter("ctaToken")}
                  />
                </div>
              </div>              
            </Col>}
          <Col xs="12" md={isMixNetworkUrl ? "6" : "4"}>
            <Nav className="nav-footer justify-content-center justify-content-md-end">
              <NavItem>
              <NavLink href={isMixNetworkUrl ? "https://mixnetworks.com/about/" : "https://numhub.com/about"} target="_blank">
                  <FormattedMessage id="footer.aboutus" />
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink href={isMixNetworkUrl ? "https://mixnetworks.com/privacy-policy/" : "https://numhub.com/privacy-policy"} target="_blank">
                  <FormattedMessage id="footer.privacyPolicy" />
                </NavLink>
              </NavItem>
              {!isMixNetworkUrl && (
                <>
                  <NavItem>
                    <NavLink href="https://numhub.com/dpa" target="_blank">
                      <FormattedMessage id="footer.dpa" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://numhub.com/porting-saas-license" target="_blank">
                      <FormattedMessage id="footer.SaaS Agreement" />
                      </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
          </Col>
        </>
          )}
        </Row>
      </Container>
    </footer>
  );
}
