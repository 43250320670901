import React, { useContext, useState, useRef, useCallback, useEffect } from "react";
import MenuItem from "./MenuItem";
import classnames from "classnames";
import { Nav, Navbar, NavbarBrand, NavLink } from "reactstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { BodyClassUtil } from "services/util/BodyClassUtil";
import Authorize from "../authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";
import { AppContext } from "services/appContext/AppContext";
import TooltipOnHover from "components/common/TooltipOnHover";
import { authorize } from "services/authorization/AuthorizationService";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { Link } from "react-router-dom";


const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;
const isCspireUrl = EnvironmentUtil.isCspire
type Props = {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
  windowWidth: number;
};

export default function Sidebar(props: Props) {
  const intl = useIntl();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const [isNonSpidProfileSelected] = useState(() => appContext.localStorageInfo.selectedProfile?.nonSpIdCompany);
  const isUserAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.Admin);

  const onMouseEnterSidenav = () => {
    if (!BodyClassUtil.hasClass("g-sidenav-pinned")) {
      BodyClassUtil.addClass("g-sidenav-show");
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!BodyClassUtil.hasClass("g-sidenav-pinned")) {
      BodyClassUtil.removeClass("g-sidenav-show");
    }
  };

  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      props.toggleSidenav();
    }
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (sidebarRef.current && !props.sidenavOpen) {
      const target = event.target as Node;
      if (!sidebarRef.current.contains(target)) {
        closeSidenav()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarRef, props.sidenavOpen]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Navbar
      className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white overflow-x-hidden"
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}>
      <div className="scrollbar-inner" ref={sidebarRef}>
        <div>
         <NavbarBrand to="/" tag={Link}>
           {isMixNetworkUrl ? (
             <img
               alt="MixNetwork"
               src={require("assets/img/MIXLogo_HighRes.png")}
               className="mix-sidebar-img"
             />
           ) : isCspireUrl ? (
             <img
               alt="CSpire"
               src={require("assets/img/cspire_business_logo.svg")}
               className="cspire-sidebar-img"
             />
           ) : (
             <img
               alt="PortControl"
               src={require("assets/img/pc_color_no_tagline.svg")}
               className="pc-sidebar-img"
             />
           )}
         </NavbarBrand>
        </div>
        <div className="sidenav-header d-flex align-items-center">
          <div className="navbar-brand">
            <h2>
              <FormattedMessage id="sidebar.menu" />
            </h2>
          </div>
          <div className="ml-auto">
            {props.windowWidth <= 1500 && <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: props.sidenavOpen
              })}
              onClick={props.toggleSidenav}>
              <div className="sidenav-toggler-inner">
                <i className={`sidenav-toggler-line ${theme === "light" ? "sidenav-toggler-line-light" : ""}`} />
                <i className={`sidenav-toggler-line ${theme === "light" ? "sidenav-toggler-line-light" : ""}`} />
                <i className={`sidenav-toggler-line ${theme === "light" ? "sidenav-toggler-line-light" : ""}`} />
              </div>
            </div>}
          </div>
        </div>
        <div className="navbar-inner">
          <Nav navbar>
            <Authorize userPermissions={[PermissionType.Explore]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.explore" })}
                linkTo="/NumberExplorer"
                iconClassName={`fas fa-search ${theme === "light" ? "dblue-color" : "text-info"}`}
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
              />
            </Authorize>
            <Authorize userPermissions={[PermissionType.Network, PermissionType.TradingPartners]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.network" })}
                linkTo="/NetworkExplorer"
                iconClassName={`fas fa-signal ${theme === "light" ? "dblue-color" : "text-orange"}`}
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
                highlightUrls={["/NetworkExplorer", "/Inventory", "/TradingPartners"]}
              >
                <MenuItem
                  title={intl.formatMessage({ id: "sidebar.network.dashboard" })}
                  linkTo="/NetworkExplorer"
                  toggleSidenav={props.toggleSidenav}
                  closeSidenav={closeSidenav}
                />
                <MenuItem
                  title={intl.formatMessage({ id: "sidebar.network.inventory" })}
                  linkTo="/Inventory"
                  toggleSidenav={props.toggleSidenav}
                  closeSidenav={closeSidenav}
                />
                {
                  authorize(PermissionType.TradingPartners) ?
                    <MenuItem
                      title={intl.formatMessage({ id: "sidebar.network.tradingPartners" })}
                      linkTo={"/TradingPartnerUser"}
                      toggleSidenav={props.toggleSidenav}
                      closeSidenav={closeSidenav}
                    /> :
                    <NavLink disabled>
                      <TooltipOnHover />
                    </NavLink>
                }
              </MenuItem>
            </Authorize>
            <Authorize spidPermissions={[PermissionType.SOARead, PermissionType.PreOrderFree]} userPermissions={[PermissionType.CarrierProfiles]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.orders" })}
                linkTo="/Orders"
                highlightUrls={["/Orders", "/Audits", "/QuerySV"]}
                iconClassName={`fas fa-exchange-alt ${theme === "light" ? "dblue-color" : "text-red"}`}
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}>
                <MenuItem
                  title={intl.formatMessage({ id: "sidebar.orders.dashboard" })}
                  linkTo="/Orders"
                  toggleSidenav={props.toggleSidenav}
                  closeSidenav={closeSidenav}
                />
                <Authorize spidPermissions={[PermissionType.SOARead]}
                  vpopDisabled={true}>
                  {((!isMixNetworkUrl && !isNonSpidProfileSelected) || (isMixNetworkUrl && isUserAdmin)) && (
                    <>
                      <MenuItem
                        title={intl.formatMessage({ id: "sidebar.orders.querySV" })}
                        linkTo="/QuerySV"
                        toggleSidenav={props.toggleSidenav}
                        closeSidenav={closeSidenav}
                      />
                      <MenuItem
                        title={intl.formatMessage({ id: "sidebar.orders.audit" })}
                        linkTo="/Audits"
                        toggleSidenav={props.toggleSidenav}
                        closeSidenav={closeSidenav}
                      />
                    </>
                  )}
                </Authorize>
              </MenuItem>
            </Authorize>
            <Authorize
              userPermissions={[PermissionType.Reports, PermissionType.CompanyAdmin]}
              vpopDisabled={true}
              nonSpidDisabled={true}>
                { (!isMixNetworkUrl || (isMixNetworkUrl && isUserAdmin)) && 
                  <MenuItem
                    title={intl.formatMessage({ id: "sidebar.reports" })}
                    linkTo="/Reports"
                    iconClassName={`fas fa-chart-pie ${theme === "light" ? "dblue-color" : ""}`}
                    toggleSidenav={props.toggleSidenav}
                    closeSidenav={closeSidenav}
                  />
                }
            </Authorize>
            <Authorize userPermissions={[PermissionType.WorkQueue]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.workQueue" })}
                linkTo="/WorkQueue"
                iconClassName={`fas fa-tasks-alt ${theme === "light" ? "dblue-color" : ""}`}
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
              />
            </Authorize>
            { isMixNetworkUrl && (
              <Authorize userPermissions={[PermissionType.CustomerInventory]}>
                <MenuItem
                  title={intl.formatMessage({ id: "sidebar.network.customerInventory" })}
                  linkTo="/CustomerInventory"
                  iconClassName={`fas fa-address-book ${theme === "light" ? "dblue-color" : ""}`}
                  toggleSidenav={props.toggleSidenav}
                  closeSidenav={closeSidenav}
                />
              </Authorize>
            )
            }
            <Authorize
              userPermissions={[
                PermissionType.Admin,
                PermissionType.CompanyAdmin,
                PermissionType.Webhooks
              ]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.manage" })}
                linkTo="/Manage"
                highlightUrls={["/Manage", "/Webhooks"]}
                iconClassName={`fas fa-cog ${theme === "light" ? "dblue-color" : ""}`}
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}>
                <Authorize userPermissions={[PermissionType.Admin, PermissionType.CompanyAdmin]}>
                  <MenuItem
                    title={intl.formatMessage(!isMixNetworkUrl || (isMixNetworkUrl && isUserAdmin) ? {id: "sidebar.usersAndOrganizations" } : {id: "sidebar.users"})}
                    linkTo="/Manage"
                    toggleSidenav={props.toggleSidenav}
                    closeSidenav={closeSidenav}
                  />
                </Authorize>
                <Authorize userPermissions={[PermissionType.Webhooks]}>
                  <MenuItem
                    title={intl.formatMessage({ id: "sidebar.notifications" })}
                    linkTo="/Notifications"
                    toggleSidenav={props.toggleSidenav}
                    closeSidenav={closeSidenav}
                  />
                </Authorize>
                <Authorize userPermissions={[PermissionType.Admin]}>
                  <MenuItem
                    title={intl.formatMessage({ id: "sidebar.reports.adminReports" })}
                    linkTo="/AdminReports"
                    toggleSidenav={props.toggleSidenav}
                    closeSidenav={closeSidenav}
                  />
                </Authorize>
              </MenuItem>
            </Authorize>
            <Authorize userPermissions={[PermissionType.Admin]}>
                <MenuItem
                  title={intl.formatMessage({ id: "sidebar.webhookLogs" })}
                  linkTo="/UrlNotifications"
                  iconClassName={`fas fa-bell ${theme === "light" ? "dblue-color" : ""}`}
                  toggleSidenav={props.toggleSidenav}
                  closeSidenav={closeSidenav}
                />
              </Authorize>
          </Nav>
        </div>
        {!isMixNetworkUrl && <div className="numhub-sidebar">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="NumbHub"
              src={require("assets/img/num_hub_color_logo.svg")}
              className="numhub-sidebar-logo"
            />
          </NavbarBrand>
        </div>}
      </div>
    </Navbar>
  );
}
