import React, { useState } from "react";
import { OrderCardModel } from "components/orders/types/OrderCardModel";
import { OrderCardStatus } from "components/orders/types/OrderCardStatus";
import { useCallback, useContext } from "react";
import { PortApi } from "services/apis/PortApi";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import { PortInModel, WholeSalePortInModel } from "services/apis/types/port/PortInModel";
import { PortInSuccessModel } from "services/apis/types/port/PortInSuccessModel";
import { handleError, getErrors } from "services/util/ApiUtil";
import { PortInAccountModel } from "../../components/orders/types/PortInAccountModel";
import { ApiError } from "components/common/types/ApiError";
import { ApiErrorType } from "components/common/types/ApiErrorType";
import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";
import { distinctFilter } from "services/util/ArrayUtil";
import { OrderApi } from "services/apis/OrderApi";
import { CsrOnlyModel } from "services/apis/types/port/CsrOnlyModel";
import { undefinedIfEmpty } from "services/util/StringUtil";
import { PortProposalDto } from "services/apis/types/port/PortProposalDto";
import { AppContext } from "services/appContext/AppContext";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

export const useCreateOrders = (
    accountModels: PortInAccountModel[],
    setAccountModel: (newAccountModel: PortInAccountModel, index: number) => void,
    setAccountModels: React.Dispatch<React.SetStateAction<PortInAccountModel[]>>,
    orderRequestType: OrderRequestType,
    uploadOrderDocuments: (orderCardModel: OrderCardModel, accountIndex: number) => Promise<void[]>,
    removeDocumentsThatFailedToUpload: (accountIndex: number) => void,
    portProposals?: PortProposalDto
) => {

    const prePortInModelUpdate = (portInModel: PortInModel): void => {
        // bypass accountName and authorizedName api validation if "CSR" has been requested.
        const isNullOrEmpty = (value: string) => value === undefined || value?.trim() === "";
        if (portInModel.csrRequested === true && portInModel.customerDetails !== undefined) {
            portInModel.customerDetails.accountName = isNullOrEmpty(portInModel.customerDetails.accountName) ? "N/A" : portInModel.customerDetails.accountName;
            portInModel.customerDetails.authorizedName = isNullOrEmpty(portInModel.customerDetails.authorizedName) ? "N/A" : portInModel.customerDetails.authorizedName;
        }
    }

    const prePortInWholesaleModelUpdate = (portInModel: WholeSalePortInModel): void => {
        // bypass accountName and authorizedName api validation if "CSR" has been requested.
        const isNullOrEmpty = (value: string) => value === undefined || value?.trim() === "";
        if (portInModel.csrRequested === true && portInModel.customerDetails !== undefined) {
            portInModel.customerDetails.accountName = isNullOrEmpty(portInModel.customerDetails.accountName) ? "N/A" : portInModel.customerDetails.accountName;
            portInModel.customerDetails.authorizedName = isNullOrEmpty(portInModel.customerDetails.authorizedName) ? "N/A" : portInModel.customerDetails.authorizedName;
        }
    }

    const autoActivatioinDate = (overviewModel: PortInAccountModel) => {
        const minDateTime = new Date('0001-01-01T00:00:00Z');
        const formattedMinDateTime = minDateTime.toISOString();
        return overviewModel.autoActivationDate ? overviewModel.autoActivationDate : formattedMinDateTime;
    }

    const { appContext } = useContext(AppContext);
    const [selectedProfile] = useState(()=> appContext.localStorageInfo.selectedProfile);
    const [isVpop] = useState(()=> selectedProfile?.external);

    const mapOrderCardModelToPortInModel = useCallback(
        (
            orderCard: OrderCardModel,
            overviewModel: PortInAccountModel,
            orderRequestType: OrderRequestType,
            portProposals: PortProposalDto | undefined
        ): PortInModel => {
            var model: PortInModel = {
                orderRequestType,
                customerDetails: overviewModel.customerDetails,
                dueDate: overviewModel.dueDate ? overviewModel.dueDate.toDate() : null,
                autoActivationDate: autoActivatioinDate(overviewModel),
                timeZone: overviewModel.timeZone ? overviewModel.timeZone : "",
                loosingProvider: orderCard.loosingSpid,
                orderHandler: orderRequestType === OrderRequestType.OrderOnly ? OrderHandlerType.ServiceProvider : orderCard.orderHandler,
                portToOrginal: orderCard.portToOrginal,
                csrRequested: overviewModel.csrRequested,
                projectId: overviewModel.projectId,
                fullOrPartialPort: overviewModel.fullOrPartialPort,
                numbers: orderCard.numbers.map((orderNumber: any) => ({
                    number: orderNumber.number,
                    lrn: orderCard.portToOrginal ? "" : orderNumber.selectedProfile?.lrn,
                    svType: orderNumber.selectedProfile?.svType,
                    billingId: orderNumber.selectedProfile?.billingId,
                    dpcSsnInfo: orderCard.portToOrginal ? null : orderNumber.selectedProfile?.networkData,
                    optionalData: orderNumber.selectedProfile?.optionalData,
                    userLocationType: orderNumber.selectedProfile?.endUserLocationType,
                    userLocationValue: orderNumber.selectedProfile?.endUserLocation
                })),
                adminProjectId: portProposals?.adminProjectId
            }
            prePortInModelUpdate(model);

            return model;
        },
        []
    );

    const mapOrderCardModelToWholeSalePortInModel = useCallback(
        (
            orderCard: OrderCardModel,
            overviewModel: PortInAccountModel,
            orderRequestType:  OrderRequestType.PreOrderAndOrder,
            portProposals: PortProposalDto | undefined
        ): WholeSalePortInModel => {
            var model: WholeSalePortInModel = {
                orderRequestType,
                customerDetails: overviewModel.customerDetails,
                dueDate: overviewModel.dueDate ? overviewModel.dueDate.toDate() : null,
                autoActivationDate: autoActivatioinDate(overviewModel),
                timeZone: overviewModel.timeZone ? overviewModel.timeZone : "",
                loosingProvider: orderCard.loosingSpid,
                csrRequested: overviewModel.csrRequested,
                projectId: overviewModel.projectId ? overviewModel.projectId : portProposals?.projectId,
                numbers: orderCard.numbers.map((orderNumber: any) => ({
                    number: orderNumber.number,
                    lrn: orderCard.portToOrginal ? "" : orderNumber.selectedProfile?.lrn,
                    svType: orderNumber.selectedProfile?.svType,
                    billingId: orderNumber.selectedProfile?.billingId,
                    dpcSsnInfo: orderCard.portToOrginal ? null : orderNumber.selectedProfile?.networkData,
                    optionalData:  orderNumber.selectedProfile?.optionalData,
                    userLocationType: orderNumber.selectedProfile?.endUserLocationType,
                    userLocationValue: orderNumber.selectedProfile?.endUserLocation
                })),
                gainingAltSpid: appContext.localStorageInfo.selectedProfile?.altSpid,
                loosingAltSpid: portProposals?.orders[0].altSpid,
                adminProjectId: portProposals?.adminProjectId,
                companyId: appContext.localStorageInfo.selectedProfile?.companyId
            }
            prePortInWholesaleModelUpdate(model);
            return model;
        },
        []
    );

    const mapPortInModelToCsrOnlyModel = useCallback((portInModel: PortInModel, adminProjectId: string | undefined) => {
            return {
                orderHandler: portInModel.orderHandler,
                projectId: portInModel.projectId,
                losingSpId: portInModel.loosingProvider,
                dueDate: portInModel.dueDate,
                numbers: portInModel.numbers,
                customerDetails: portInModel.customerDetails,
                fullOrPartialPort: portInModel.fullOrPartialPort,
                adminProjectId: adminProjectId
            } as CsrOnlyModel
        },
        []
    );

    const findCardFilter = (newOrderCard, orderCard) =>
        newOrderCard.loosingSpid === orderCard.loosingSpid &&
        newOrderCard.lata === orderCard.lata &&
        newOrderCard.lrn === orderCard.lrn &&
        newOrderCard.portToOrginal === orderCard.portToOrginal &&
        newOrderCard.numbers.map(n => n.number)
            .every(n => orderCard.numbers.map(n2 => n2.number).indexOf(n) > -1);

    const createOrder = useCallback(
        async (orderCard: OrderCardModel, accountIndex: number) => {
            const portInModel: PortInModel = mapOrderCardModelToPortInModel(orderCard, accountModels[accountIndex], orderRequestType, portProposals);
            const csrOnlyModel : CsrOnlyModel = mapPortInModelToCsrOnlyModel(portInModel, portProposals?.adminProjectId);
            const wholeSalePortInModel: WholeSalePortInModel = mapOrderCardModelToWholeSalePortInModel(orderCard, accountModels[accountIndex], OrderRequestType.PreOrderAndOrder, portProposals);
            try {
                const { id: orderId } = (orderRequestType === OrderRequestType.CSROnly 
                    ?  await OrderApi.csrOnlyOrder(csrOnlyModel)
                    :  isMixNetworkUrl ? await PortApi.wholeSalePortIn(wholeSalePortInModel) : await PortApi.portIn(portInModel)) as PortInSuccessModel;

                const newOrderCards = [...accountModels[accountIndex].orderCards];

                const currentOrderIdx = newOrderCards.findIndex((newCard) => findCardFilter(newCard, orderCard));
                if (currentOrderIdx > -1) {
                    newOrderCards[currentOrderIdx].orderId = orderId;
                    newOrderCards[currentOrderIdx].status = OrderCardStatus.Created;
                }

                setAccountModels((previous) => {
                    var updatedAccounts = [...previous];
                    updatedAccounts[accountIndex].orderCards = [...newOrderCards];
                    return updatedAccounts;
                });
            } catch (error) {
                handleError(error as ApiError);

                setAccountModels((previous) => {
                    return previous.map((accountModel, index) => {
                        if (index === accountIndex) {
                            const newOrderCards = accountModel.orderCards.map((orderCard) => ({ ...orderCard }));
                            const currentOrder = newOrderCards.find((newCard)=> findCardFilter(newCard, orderCard));
                            if (currentOrder) {
                                currentOrder.status = OrderCardStatus.HasErrors;
                            }

                            const newApiErrors = accountModel.apiErrors ? accountModel.apiErrors.concat(
                                getErrors((error).fieldErrors).map((error) => {
                                    return { message: error, type: ApiErrorType.Danger };
                                })
                            ) : getErrors(error.fieldErrors).map((error) => {
                                return { message: error, type: ApiErrorType.Danger };
                            });

                            return {
                                ...accountModel,
                                orderCards: newOrderCards,
                                apiErrors: newApiErrors.filter(distinctFilter) as ApiError[]
                            };
                        }
                        return accountModel;
                    });
                });
            }
        },
        [accountModels, mapOrderCardModelToPortInModel, mapPortInModelToCsrOnlyModel, orderRequestType, setAccountModels]
    );

    const resetAccountErrors = useCallback(
        (accountModel: PortInAccountModel, newOrderCards: OrderCardModel[], accountIndex: number) => {
            setAccountModel(
                { ...accountModel, apiErrors: undefined, orderCards: newOrderCards },
                accountIndex
            );
        },
        [setAccountModel]
    );

    const validateAccountModelsAndSetApiErrors = useCallback((accountModel: PortInAccountModel, accountIndex: number) => {
        
        let errors: string[] = [];

        // check for 'missing' orderHandler in order cards
        if((!isMixNetworkUrl) && !isVpop) {
            errors = accountModel.orderCards
            .filter(c => c.orderHandler === OrderHandlerType.SelectOne)
                .map(c => `Order Handler has not been provided for account ${accountModel.customerDetails.accountName}`);
        }
        if(orderRequestType === OrderRequestType.CSROnly ||orderRequestType === OrderRequestType.PreOrderAndOrder){
            if (!accountModel.fullOrPartialPort) {
                errors.push(`Full or Partial Port has not been provided for account ${accountModel.customerDetails.accountName}`);
            }
        }
        // check for document upload in Mix
        if (isMixNetworkUrl) {
            const missingDocumentsErrors = accountModel.orderCards
                .filter(c => c.documents.length === 0)
                .map(c => `Document not uploaded for account ${accountModel.customerDetails.accountName}`)
            errors = errors.concat(missingDocumentsErrors);
        }
        // validations for vpop orders
        if ((isVpop || isMixNetworkUrl) && !accountModel.csrRequested) {
            const requiredFields = Object.keys(accountModel.customerDetails)
            .filter(fieldKey=> ['accountName',"billingPhoneNumber", "authorizedName"].map(f=> f.toLocaleLowerCase())
                ?.includes(fieldKey.toLocaleLowerCase()) && undefinedIfEmpty(accountModel.customerDetails[fieldKey]) === undefined);

            if (accountModel.customerDetails && requiredFields.length > 0) {

                let missingFieldMessageFn = (field) => `${field[0].toLocaleUpperCase()+field.substr(1)} has not been provided`;
                errors = errors.length > 0
                    ? [...errors, ...requiredFields.map(f => missingFieldMessageFn(f))]
                    : [...requiredFields.map(f => missingFieldMessageFn(f))]
            }
        }
        // check for missing csrRequested required fields in customer details
        const csrRequestedMissingRequiredFields = 
            Object.keys(accountModel.customerDetails)
                .filter(fieldKey=> ["billingPhoneNumber", "authorizedName"].map(f=> f.toLocaleLowerCase())
                    ?.includes(fieldKey.toLocaleLowerCase()) && undefinedIfEmpty(accountModel.customerDetails[fieldKey]) === undefined);

        !accountModel.dueDate && csrRequestedMissingRequiredFields.push(
            Object.keys(accountModel)
            .filter(fieldKey=> ["dueDate"].map(f=> f.toLocaleLowerCase())
                ?.includes(fieldKey.toLocaleLowerCase()) && accountModel.dueDate === null).toString());     

        if (accountModel.csrRequested && 
            csrRequestedMissingRequiredFields.length > 0) {

                let missingFieldMessageFn = (field) => `${field[0].toLocaleUpperCase()+field.substr(1)} has not been provided`;
                errors = errors.length > 0 
                    ? [...errors, ...csrRequestedMissingRequiredFields.map(f => missingFieldMessageFn(f))]
                    : [...csrRequestedMissingRequiredFields.map(f => missingFieldMessageFn(f))]
        }

        const portInMissingDueDate = 
            Object.keys(accountModel)
                .filter(fieldKey=> ["dueDate"].map(f=> f.toLocaleLowerCase())
                    ?.includes(fieldKey.toLocaleLowerCase()) && accountModel.dueDate === null);

        if (!accountModel.csrRequested && portInMissingDueDate.length > 0) {
            let missingFieldMessageFn = (field) => `${field[0].toLocaleUpperCase()+field.substr(1)} has not been provided`;
            errors = errors.length > 0 
                ? [...errors, ...portInMissingDueDate.map(f => missingFieldMessageFn(f))]
                : [...portInMissingDueDate.map(f => missingFieldMessageFn(f))]
        }

        if (errors.length > 0) {
            setAccountModel({
                ...accountModel,
                apiErrors: errors.map(errMsg => { return { message: errMsg, type: ApiErrorType.Danger } }) as ApiError[]
            }, accountIndex);

            return false;
        }

        return true;

    }, [setAccountModel])

    const createOrdersForAccount = useCallback(
        async (accountModel: PortInAccountModel, accountIndex: number, portProposals?: PortProposalDto | undefined) => {

            var isValid = validateAccountModelsAndSetApiErrors(accountModel, accountIndex);
            if (!isValid)
                return;

            if (accountModel.orderCards.some((x) => !x.orderId)) {
                const newOrderCards = accountModel.orderCards.map((orderCard) => ({
                    ...orderCard,
                    status: OrderCardStatus.NotSubmitted,
                    documents: orderCard.documents.map((document) => ({ ...document, deleteDisabled: true }))
                }));
                resetAccountErrors(accountModel, newOrderCards, accountIndex);

                await Promise.all(
                    newOrderCards
                        .filter((x) => !x.orderId)
                        .map((orderCard) => createOrder(orderCard, accountIndex))
                );
                await Promise.all(
                    accountModel.orderCards.map((orderCard) => uploadOrderDocuments(orderCard, accountIndex))
                );
                removeDocumentsThatFailedToUpload(accountIndex);
            }
        },
        [createOrder, removeDocumentsThatFailedToUpload, resetAccountErrors, uploadOrderDocuments, validateAccountModelsAndSetApiErrors]
    );

    const createOrdersForAllAccounts = useCallback(async () => {
        await Promise.all(
            accountModels.map((overviewModel, index) => createOrdersForAccount(overviewModel, index))
        );
    }, [accountModels, createOrdersForAccount]);

    return { createOrdersForAllAccounts, createOrdersForAccount, validateAccountModelsAndSetApiErrors };
};
